import * as React from'react'
import Typo from '@mui/material/Typography'

import{IPageProps}from'../types'
import Button from'hendrixmaterial/button'
import Box from'hendrixmaterial/box'

import{getRandomNumber,useTimer}from'../utils'

const plays=[true,false]

export default function Index({props}:IPageProps){
  const[count,setCount]=useTimer(50)
  const[play,setPlay]=React.useState(null)
  const[didPlay,setDidPlay]=React.useState(false)
  

  React.useEffect(()=>{
    if(count>0){
      setPlay(p=>!p)
    }
    if(count===0){
      setPlay(plays[getRandomNumber(plays.length,0)])
    }
  },[count])


  const BtnPlay=<Button
    onClick={()=>{
      setPlay(plays[getRandomNumber(plays.length,0)]);
      setCount(20)
      if(!didPlay){setDidPlay(true)}
    }}
    children={<Typo variant='h6'>play</Typo>}
    variant='outlined'
    size='large'
    disabled={count>0}
    color='primary'
  />


  const beforeFirstPlay=<Typo variant='h2' color='transparent' children='penelope'/>


  const _Main=<Box sx={{height:'100%',width:'fit-content',margin:'auto'}}>
    <Typo variant='h2' color={count>0?'GrayText':'primary'}>
      {play===true?'penelope':null}
      {play===false?'cuddles':null}
    </Typo>
  </Box>


  return <>
    {BtnPlay}
    {!didPlay?beforeFirstPlay:_Main}
  </>
}
