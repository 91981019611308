import * as React from'react'
import Typo from '@mui/material/Typography'

import{IPageProps}from'../types'
import Button from'hendrixmaterial/button'
import Box from'hendrixmaterial/box'

import{getRandomNumber,useTimer}from'../utils'

const plays=['rock','paper','scissors']
const playsRev=['shoot!','scissors','paper','rock']

export default function Index({props}:IPageProps){
  const[count,setCount]=useTimer(750)
  const[play,setPlay]=React.useState(null)
  const[didPlay,setDidPlay]=React.useState(false)


  React.useEffect(()=>{
    if(count===0){
      setPlay(plays[getRandomNumber(plays.length,0)])
      setCount(false)
    }
  },[count,setCount])

  const BtnPlay=<Button
    onClick={()=>{
      setPlay(null)
      setCount(4)
      if(!didPlay){setDidPlay(true)}
    }}
    children={<Typo variant='h6'>play</Typo>}
    variant='outlined'
    size='large'
    disabled={count>0}
    color='primary'
  />


  // const _Title=<>
  //   <Typo variant='h3'>
  //     Rock, Paper, Scissors
  //   </Typo>
  //   <Typo variant='subtitle1'>
  //     pick your opponent or play the computer
  //   </Typo>
  // </>


  const beforeFirstPlay=<Typo variant='h2' color='transparent' children='scissors'/>

  const _Main=<Box sx={{height:'100%',width:'fit-content',margin:'auto'}}>
    <Typo variant='h2' color={count>0?'GrayText':'primary'}>
      {count!==null?
        play||playsRev[count-1]
      :null}
    </Typo>
  </Box>


  return <>
    {/* {_Title} */}
    {/* <br/> */}
    {BtnPlay}
    {!didPlay?beforeFirstPlay:_Main}
  </>
}
