import * as React from'react'

export const getRandomNumber=(range=10,start=1)=>Math.floor(Math.random()*range)+start

export const useTimer=(timeout=1000)=>{
  const[count,setCount]=React.useState(null)

  React.useEffect(()=>{
    if(count>0){
      const timer=setTimeout(()=>{setCount(c=>c-1)},timeout)
      return ()=>clearTimeout(timer)
    }
  },[count,timeout])

  return[count,setCount]
}
