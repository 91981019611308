import React from'react'

import{makeDialog}from'../makeDialog'

import PickANumber from 'apps/games/pickANumber'

import Box from'hendrixmaterial/box'

export const dialogPickANumber=makeDialog({
  page:'games',
  hash:'1-10',
  buttonProps:{
    children:`1-10`,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:'Pick a number 1-10',
    },
  },

  dialogContentProps:{
    sx:{
      width:'75vw',
      maxWidth:'100%',
      padding:'3px'
    },
    children:<Box
      sx={{
        width:'100%',
        margin:'auto',
        display:'flex',
        flexDirection:'column'
      }}

      children={<>
        <br/>
        <PickANumber/>
      </>}
    />
  },
})

export default dialogPickANumber
