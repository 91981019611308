import React from'react'

import{makeDialog}from'../makeDialog'

import PenelopeOrCuddles from 'apps/games/penelopeOrCuddles'

import Box from'hendrixmaterial/box'

export const dialogPenelopeCuddles=makeDialog({
  page:'games',
  hash:'penelope-or-cuddles',
  buttonProps:{
    children:`Penelope Or Cuddles`,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:'Penelope Or Cuddles',
    },
  },

  dialogContentProps:{
    sx:{
      width:'75vw',
      maxWidth:'100%',
      padding:'3px'
    },
    children:<Box
      sx={{
        width:'100%',
        margin:'auto',
        display:'flex',
        flexDirection:'column'
      }}

      children={<>
        <br/>
        <PenelopeOrCuddles/>
      </>}
    />
  },
})

export default dialogPenelopeCuddles
