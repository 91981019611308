import * as React from'react'
import Typo from '@mui/material/Typography'


import{IPageProps}from'../types'
import Button from'hendrixmaterial/button'
import Box from'hendrixmaterial/box'

import{getRandomNumber,useTimer}from'../utils'


export default function Index({props}:IPageProps){
  const[count,setCount]=useTimer(50)
  const[play,setPlay]=React.useState(null)
  const[didPlay,setDidPlay]=React.useState(false)

  React.useEffect(()=>{
    if(count&&count>=0){
      setPlay(getRandomNumber(10,1))
    }
  },[count])

  const BtnPlay=<Button
    onClick={()=>{
      setCount(20)
      if(!didPlay){setDidPlay(true)}
    }}
    children={<Typo variant='h6'>play</Typo>}
    variant='outlined'
    size='large'
    disabled={count>0}
    color='primary'
  />

  // const _Title=<>
  //   <Typo variant='h3'>
  //     Pick a number 1-10
  //   </Typo>
  // </>

  const beforeFirstPlay=<Typo variant='h2' color='transparent' children='10'/>

  const _Main=<Box sx={{height:'100%',width:'fit-content',margin:'auto'}}>
    <Typo variant='h2' color={count>0?'GrayText':'primary'}>
      {play}
    </Typo>
  </Box>


  return <>
    {/* {_Title} */}
    {/* <br/> */}
    {BtnPlay}
    {!didPlay?beforeFirstPlay:_Main}
  </>
}
