import{makePage}from'../makePage'

import penelopeOrCuddles from'./penelopeOrCuddles'
import rockPaperScissors from'./rockPaperScissors'
import pickANumber from'./pickANumber'


const PageGames=(props)=>makePage({content:[penelopeOrCuddles,pickANumber,rockPaperScissors],...props})

export default PageGames
