import React from'react'

import{makeDialog}from'../makeDialog'

import RockPaperScissors from 'apps/games/rockPaperScissors'

import Box from'hendrixmaterial/box'

export const dialogRockPaperScissors=makeDialog({
  page:'games',
  hash:'rock-paper-scissors',
  buttonProps:{
    children:`Rock, Paper, Scissors`,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:'Rock, Paper, Scissors',
    },
  },

  dialogContentProps:{
    sx:{
      width:'75vw',
      maxWidth:'100%',
      padding:'3px'
    },
    children:<Box
      sx={{
        width:'100%',
        margin:'auto',
        display:'flex',
        flexDirection:'column'
      }}

      children={<>
        <br/>
        <RockPaperScissors/>
      </>}
    />
  },
})

export default dialogRockPaperScissors
